<template>
  <div class="shoppie-list">
    <ul v-if="!clickable">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="`/${lang}/cryptoshoppie/${item.id}`"
      >
        <li class="shoppie-list__item">
          <div class="char-placeholder">
            <div
              v-if="item.isInGame || item.isOnMarket || item.breedingAmount"
              class="char-placeholder__badge"
              :style="{ background: badgeBg(item) }"
            >
              {{
                item.isInGame
                  ? $t("char-badge-game")
                  : item.isOnMarket
                  ? $t("char-badge-sale")
                  : $t("char-badge-rent")
              }}
            </div>
            <div class="char-placeholder__img model-shadow">
              <img :src="typeof item === 'string' ? item : item.image" alt="" />
            </div>

            <div class="char-placeholder__bottom" v-if="!hideRarity">
              <div class="char-placeholder__title">
                {{ $t("char-rarity") }}
              </div>

              <div class="char-placeholder__amount">75-100</div>
            </div>
            <div class="char-placeholder__bottom" v-if="filterable">
              <button
                @click="applyAsFilter(item.phenotypeShortKey)"
                class="game-btn"
              >
                {{
                  phenotypeShortKey === item.phenotypeShortKey
                    ? "Reset filter"
                    : "Apply as filter"
                }}
              </button>
            </div>
          </div>
        </li>
      </router-link>
    </ul>

    <ul v-else>
      <li
        v-for="(item, index) in items"
        :key="index"
        class="shoppie-list__item"
      >
        <div class="char-placeholder">
          <div class="char-placeholder__img model-shadow">
            <img :src="typeof item === 'string' ? item : item.image" alt="" />
          </div>

          <div class="char-placeholder__bottom" v-if="!hideRarity">
            <div class="char-placeholder__title">
              {{ $t("char-rarity") }}
            </div>

            <div class="char-placeholder__amount">75-100</div>
          </div>
          <div class="char-placeholder__bottom" v-if="filterable">
            <button
              @click="applyAsFilter(item.phenotypeShortKey)"
              class="game-btn"
            >
              {{
                phenotypeShortKey === item.phenotypeShortKey
                  ? "Reset filter"
                  : "Apply as filter"
              }}
            </button>
          </div>
        </div>
      </li>
    </ul>

    <button
      class="shoppie-list__btn"
      v-show="showLoadMoreBtn"
      @click="loadMore"
    >
      {{ $t("shoppie-list-btn") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ShoppiesList",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    lang: {
      type: String,
      required: false,
      default: () => "en",
    },
    showLoadMoreBtn: {
      type: Boolean,
      required: true,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    filterable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideRarity: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    phenotypeShortKey: "",
  }),
  methods: {
    badgeBg(item) {
      return item.isInGame
        ? "#00C2FF"
        : item.isOnMarket
        ? "#EE4747"
        : "#ADFF00";
    },
    applyAsFilter(phenotypeShortKey) {
      if (this.phenotypeShortKey === phenotypeShortKey) {
        this.phenotypeShortKey = "";
      } else {
        this.phenotypeShortKey = phenotypeShortKey;
      }
      this.$emit("applyAsFilter", phenotypeShortKey);
    },
    loadMore() {
      this.$emit("loadMore");
    },
  },
};
</script>
