<template>
  <div class="filter">
    <button class="filter-btn" @click="isFilterModalVisible = true">
      <FilterSvg />
      {{ $t("filter-btn") }}
    </button>
    <transition name="fade">
      <keep-alive>
        <FilterModal
          v-if="isFilterModalVisible"
          @close="isFilterModalVisible = false"
          @filter="makeFilter"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import FilterSvg from "../icons/FilterSvg";
import FilterModal from "../modals/FilterModal";
export default {
  name: "Filters",
  components: { FilterModal, FilterSvg },
  data: () => ({
    isFilterModalVisible: false,
  }),
  methods: {
    makeFilter(filter) {
      this.$emit("filter", filter);
      this.isFilterModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
