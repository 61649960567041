<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M13.7333 2.73372V4.20039C13.7333 4.73372 13.3999 5.40039 13.0666 5.73372L10.1999 8.26706C9.79994 8.60039 9.53328 9.26706 9.53328 9.80039V12.6671C9.53328 13.0671 9.26661 13.6004 8.93328 13.8004L7.99994 14.4004C7.13328 14.9337 5.93328 14.3337 5.93328 13.2671V9.73372C5.93328 9.26706 5.66661 8.66706 5.39994 8.33372L4.73328 7.63372L8.61328 1.40039H12.3999C13.1333 1.40039 13.7333 2.00039 13.7333 2.73372Z"
      fill="#9984D4"
    />
    <path
      d="M7.53339 1.40039L4.08006 6.94039L2.86672 5.66706C2.53339 5.33372 2.26672 4.73372 2.26672 4.33372V2.80039C2.26672 2.00039 2.86672 1.40039 3.60006 1.40039H7.53339Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "FilterSvg",
};
</script>

<style scoped></style>
