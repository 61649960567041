<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-collection popup-filter">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("filter-btn") }}
          </div>

          <div class="align-center">
            <MainButton
              :text="$t('reset-filters-btn')"
              :disabled="filterBtnDisabled"
              @click="resetFilter"
            />

            <PageButton @click="close">
              <template v-slot:icon>
                <Close />
              </template>
            </PageButton>
          </div>
        </div>
        <div class="popup__content">
          <div class="filters-grid">
            <div class="filters-grid__head">
              <div class="filters-col">
                <div class="filters-grid__title">
                  {{ $t("filters-grid-name") }}
                </div>
              </div>
              <div class="filters-col">
                <div class="filters-grid__title">
                  {{ $t("filters-grid-level") }}
                </div>
              </div>
            </div>

            <div class="filters-grid__body">
              <div
                class="filters-row"
                v-for="(item, index) in attr"
                :key="index"
              >
                <div class="filters-col">
                  <div class="filters-attr">
                    <div class="filters-attr__icon">
                      <HeartCirceSvg />
                    </div>

                    <div class="filters-attr__name">
                      {{ $t(`attr-${item.value}`) }}
                    </div>
                  </div>
                </div>

                <div class="filters-col">
                  <div class="filters-levels">
                    <button
                      class="filter-level"
                      v-for="n in 4"
                      :key="n"
                      :class="{ active: n === item.level }"
                      @click="
                        setFilter(item.value, n);
                        item.level = n;
                      "
                    >
                      {{ n }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MainButton :text="$t('apply-btn')" @click="makeFilter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import MainButton from "../common/buttons/MainButton";
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import HeartCirceSvg from "../icons/HeartCirceSvg";
export default {
  name: "FilterModal",
  mixins: [CloseModalMixin],
  components: { HeartCirceSvg, Close, PageButton, MainButton },
  data: () => ({
    attr: [
      {
        value: "body",
        level: 0,
      },
      {
        value: "eyes",
        level: 0,
      },
      {
        value: "face",
        level: 0,
      },
      {
        value: "arms",
        level: 0,
      },
      {
        value: "lags",
        level: 0,
      },
      {
        value: "head",
        level: 0,
      },
      {
        value: "tail",
        level: 0,
      },
      {
        value: "wings",
        level: 0,
      },
      {
        value: "color",
        level: 0,
      },
    ],
    filter: "",
  }),
  computed: {
    filterBtnDisabled() {
      return !this.filter.length;
    },
  },
  methods: {
    resetFilter() {
      this.filter = "";
      this.attr.forEach((i) => (i.level = 0));
      this.makeFilter();
    },
    makeFilter() {
      this.$emit(
        "filter",
        this.filter ? this.filter.substring(0, this.filter.length - 1) : ""
      );
    },
    setFilter(filter, value) {
      let val = `${filter}:${value}`;
      if (!this.filter.includes(val)) {
        this.filter += `${val},`;
      }
    },
  },
};
</script>

<style scoped></style>
